import { useCallback, useEffect, useMemo, useState } from 'react';
import { includes } from '@/utils/array';
import useFirstEffect from './useFirstEffect';
import useSessionStorage from '@/hooks/useSessionStorage';

const hiddenVariants = ['header', 'aside', 'user', 'footer'] as const;
type HiddenVariants = (typeof hiddenVariants)[number];

interface CustomStorageEvent extends Event {
  newValue: string;
}

const useHidden = () => {
  const [initialVal] = useSessionStorage<string>('layoutHidden', '');
  const [hiddenStr, setHiddenStr] = useState(initialVal);

  const hiddenArray = useMemo(() => {
    if (typeof hiddenStr === 'string') {
      return hiddenStr
        .split(',')
        .filter((s): s is HiddenVariants => includes(hiddenVariants, s));
    }
    return [];
  }, [hiddenStr]);

  const handleChange = useCallback((event: CustomStorageEvent) => {
    setHiddenStr(event.newValue);
  }, []);

  useEffect(() => {
    window.addEventListener('hidden-storage', handleChange as EventListener);
    return () => {
      window.removeEventListener(
        'hidden-storage',
        handleChange as EventListener
      );
    };
  }, [handleChange]);
  return hiddenArray;
};

export const useStorageHiddenQuery = () => {
  const [layoutHiddenStr, setHiddenStr] = useSessionStorage<string>(
    'layoutHidden',
    ''
  );
  useFirstEffect(() => {
    let query = window.location.pathname;
    let query2 = window.location.search.slice(1);
    const params1 = Object.fromEntries(new URLSearchParams(query));
    const params2 = Object.fromEntries(new URLSearchParams(query2));
    const params = Object.assign({}, params1, params2);

    if ('hidden' in params) {
      window.dispatchEvent(
        new StorageEvent('hidden-storage', {
          key: 'layoutHidden',
          newValue: params['hidden'],
        })
      );
      setHiddenStr(params['hidden']);
    }
  }, [layoutHiddenStr]);
};

export default useHidden;
