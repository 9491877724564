import React from 'react';
import { useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
// import '@arcsite/shared/styles.css';
import { useStorageHiddenQuery } from './hooks/useHidden';
import { queryClient } from './query_client';
import Router from './router';
import store from './store';
import ArchivingTasks from './components/archiving-tasks';
import { useUser } from './hooks';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { MaintenanceGuard } from './common/components/MaintenanceGuard';

const version = require('@/../package.json')['version'];
window.__ARCSITE_VERSION__ = version;

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://175008584b1f49829cc5a07de989f5a9@o302832.ingest.sentry.io/5404532',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // 只 track 10% 的 transaction
    tracesSampleRate: 0.1,

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /^safari-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /webkit-masked-url/,
      /accounts\.google\.com/i,
      /googletagmanager\.com/,
      /anonymous/,
    ],

    ignoreErrors: [
      'Unauthorized', // session 失效或者删除后端返回的error，因为会 reload page，所以不需要处理
      'ChunkLoadError', // 由于 webpack 的 code split，可能会出现加载动态的bundle网络错误，已经有外层的errorboudnary处理了
      /Failed to execute 'insertBefore|removeChild'/, // 第三方插件造成的，不好在业务层面处理

      'The object can not be found here.', // sentry related https://github.com/getsentry/sentry-javascript/issues/2342
      /Loading CSS chunk \d+ failed/, // css chunk 加载失败，属于网络问题，不需要处理
      /Failed to load Stripe.js/, // stripe sdk 加载失败，stripe会自动重试的，不需要处理
      'Load failed', // network issue
      /WeixinJSBridge/,
      /ResizeObserver loop limit exceeded/,
      /ResizeObserver loop completed with undelivered notifications./, // antd bug
      /TimeoutError/, // 请求超时
      /NetworkError/,
      /502|500/,
      /network error/,
      '网络连接已中断',
      '请求超时',
      'La connexion réseau a été perdue.',
      'WebKit遇到内部错误',
      /Failed to fetch/, // 网络错误
      /TypeError: Type error/,
      /undefined is not an object \(evaluating/, // google translate
      /null is not an object \(evaluating/, // google translate
      /Cannot redefine property: googletag/,
      /AbortError/,
      /_DumpException/,
      /Object captured as exception with keys/,
      /Object captured as promise rejection with keys/,
      /Non-Error promise rejection captured with value/,
      /cancelled/,
      'cancelado',
      /APIError/,
      /Properties can only be defined on Objects/, //edge translate
      /We could not retrieve data from the specified Element/, //stripe unmounted
      /^\w+ is not defined$/, // error from ext
      /N+1 API Call/, // dont' handle this
      /Can't find variable/, // error from ext
      /SyntaxError/,
      /Out of memory/,
      "Cannot read properties of null (reading 'endpoint')",
      "Cannot read properties of null (reading 'document')",
      "Cannot read properties of null (reading 'shadowRoot')",
      /nexusWebSocket/,
      "Cannot read properties of undefined (reading 'parentNode')", //intercom
      '_logicalFormForControl', //from  iCloud passwords chrome extension
      /NotAllowedError: The play method is not allowed by the user agent./, // media api
      /Invalid call to runtime.sendMessage/,
      'jb', // google jsi
      'Method not found',
      'No error message',
    ],

    release: process.env.APP_ENV + '@' + version,
    environment: process.env.APP_ENV,
  });
}

function SubscribeStatus() {
  const user = useUser();
  if (!user) {
    return null;
  }
  if (user.default_org_subs_expire_time * 1000 < Date.now()) {
    return (
      <div
        className="fixed top-0 left-0 right-0"
        style={{ zIndex: 9999, background: '#f5222d', height: '4px' }}
      ></div>
    );
  }
  if (user.default_org_purchase_type == 'TRIAL') {
    return (
      <div
        className="bg-warn fixed top-0 left-0 right-0"
        style={{ zIndex: 9999, height: '4px' }}
      ></div>
    );
  }
  return null;
}

export default function App() {
  useStorageHiddenQuery();
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV != 'development' && <MaintenanceGuard />}
        <Router />
        <ArchivingTasks />
        <SubscribeStatus />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}
